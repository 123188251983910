import actions from "./actions";

const initialState = {
  insuranceCo: [],
  groupNumbers: [],
  answers: [], 
  editHistory: [],
  notes: [],
  tableNotes: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
        return { ...state, ...action.payload };
    }
    case actions.SAVE_INITIAL_PROGRESS: {
      const { insuranceId, formId, groupNumberId, userId, mappedQuestions } = action.payload;

      const tempObject = []

      mappedQuestions.map(item => {
        tempObject.push({
          ...item,
          notes: [],
          answers: [],
        })

        return false
      })

      const resultObject = {
        questionSet: [ ...tempObject ],
        insuranceId,
        formId,
        groupNumberId,
        answeredBy: userId,
      }

      return Object.assign({}, state, {
        groupNumbers: state.groupNumbers.concat(resultObject),
      });
    }
    case actions.SUBMIT_WITH_TIMESTAMP: {
      // const { insuranceId, formId, groupNumberId, submitTimestamp, submittedBy, questionSet } = action.payload;
      const { groupNumberId, submitTimestamp, submittedBy, questionSet } = action.payload;
    
      // const answersCopy = [ ...state.answers ]
      const editHistoryCopy = [ ...state.editHistory ];
      const groupNumbersCopy = [ ...state.groupNumbers ];

      const index = groupNumbersCopy.map( x => x.groupNumberId ).indexOf(groupNumberId);

      groupNumbersCopy[index] = {
        ...groupNumbersCopy[index],
        questionSet,
        submitTimestamp
      };

      // state.insuranceCo
      //   .map(insurance => {
      //     if(insurance._id === insuranceId) {

      //       let groupNumberAnswerID = ""

      //       state.groupNumbers.map(gn => {
      //         if(gn.groupNumberId === groupNumberId) {
      //           return groupNumberAnswerID = gn.questionSet[1].answers[gn.questionSet[1].answers.length-1]
      //         }

      //         return false
      //       })

      //       answersCopy
      //         .filter(answer => {
      //           return answer.answerId === groupNumberAnswerID
      //         })
      //         .map(answer => {
      //           return insurance.groupNo.push({
      //             _id: groupNumberId,
      //             groupNumber: answer.answer,
      //             formId,
      //           })
      //         })
      //     }

      //     return false
      //   })

      // state.groupNumbers
      //   .filter((item) => {
      //     return item.formId === formId && item.groupNumberId === groupNumberId;
      //   })
      //   .map((item) => {
      //     return (item.submitTimestamp = submitTimestamp);
      //   });



        editHistoryCopy.push({
          id: groupNumberId,
          timestamp: submitTimestamp,
          editedBy: submittedBy,
          editedQuestion: "",
          action: "gn form submitted"
        })

      // return Object.assign({}, state, { groupNumbers: groupNumbersCopy, answer: answersCopy, editHistory: editHistoryCopy });
      return Object.assign({}, state, { groupNumbers: groupNumbersCopy, editHistory: editHistoryCopy });
    }
    case actions.SAVE_ANSWER: {

      const { 
        answer, 
        groupNumberId,
        answeredBy, 
        questionId,
        answerId,
        timestamp,
        createdAt,
        updatedAt,
        _id
      } = action.payload;
    
      const groupNumbersCopy = [ ...state.groupNumbers ]
      const answersCopy = [ ...state.answers ]
      const editHistoryCopy = [ ...state.editHistory ]

      groupNumbersCopy.map(item => {
        if (item.groupNumberId === groupNumberId) {

          // check if the question and answer is new
          const questionExist = item.questionSet
            .some(question => {
              return question.questionId === questionId
            })

          if (!questionExist) {

            item.questionSet.push({
              questionId,
              answers: [ _id ],
              notes: [],
              _id
            })

          } else {

            item.questionSet.map(question => {

              if (question.questionId === questionId) {
  
                if (question.answers.length === 0) {
  
                  question.answers.push(_id)
                } 

                const hasDuplicateAnswer = question.answers.some(qAndA => {
                  return qAndA === _id
                })
  
                if (!hasDuplicateAnswer) {
                  question.answers.push(_id)
                }
  
              } 
  
              return false
            })
          }
        }

        return false
      });

      if (answersCopy.length === 0) {

        answersCopy.push({
          answerId,
          answer,
          answeredBy,
          createdAt,
          updatedAt,
          _groupNumberId: groupNumberId,
          _id,
          id: _id
        })

        editHistoryCopy.push({
          id: answerId,
          groupNumberId,
          timestamp,
          answer,
          editedBy: answeredBy,
          editedQuestion: questionId,
          action: "answer written"
        })

      } else {

        const hasDuplicate = answersCopy
          .find(a => {
            return a.answerId === answerId
          })

        if (hasDuplicate) {

          answersCopy 
            .filter(singleAnswer => {
              return singleAnswer.answerId === answerId
            })
            .map(singleAnswer => {
              singleAnswer.answer = answer
              singleAnswer.updatedAt = updatedAt

              return false
            })

          editHistoryCopy 
            .filter(history => {
              return history.id === answerId
            })
            .map(history => {
              history.updatedAt = updatedAt
              history.editedBy = answeredBy
              history.answer = answer

              return false
            });

        } else {
          answersCopy.push({
            answerId,
            answer,
            answeredBy,
            createdAt,
            updatedAt,
            _groupNumberId: groupNumberId,
            _id,
            id: _id,
          })

          editHistoryCopy.push({
            id: answerId,
            groupNumberId,
            timestamp,
            answer,
            editedBy: answeredBy,
            editedQuestion: questionId,
            action: "answer written"
          })
        }
      }

      const returnObject = Object.assign({}, state, { groupNumbers: groupNumbersCopy, answers: answersCopy, editHistory: editHistoryCopy })
      
      return returnObject;
    }
    case actions.SAVE_ANSWER_LIST: {
      const { 
        answers, 
        groupNumberId, 
        answeredBy, 
        timestamp,
      } = action.payload;
      
      const groupNumbersCopy = [ ...state.groupNumbers ];
      const answersCopy = [ ...state.answers ];
      const editHistoryCopy = [ ...state.editHistory ];

      answers.map(({ answerId, questionId, value }) => {
        const filtered = answersCopy.filter(item => {
            return item.answerId === answerId;
        });

        if (filtered.length > 0) {
            const index = answersCopy.map( x => x.answerId ).indexOf(answerId);
            answersCopy[index] = { 
              ...answersCopy[index], 
              questionId,
              answer: value, 
              answeredBy,
              createdAt: timestamp,
              _groupNumberId: groupNumberId
            };

            editHistoryCopy 
            .filter(history => {
              return history.id === answerId
            })
            .map(history => {
              history.timestamp = timestamp
              history.editedBy = answeredBy
              history.answer = value

              return false
            })
        } else {
            answersCopy.push({
                answerId,
                questionId,
                answer: value,
                answeredBy,
                createdAt: timestamp,
                updatedAt: timestamp,
                _groupNumberId: groupNumberId
            })

            editHistoryCopy.push({
                id: answerId,
                groupNumberId,
                timestamp,
                answer: value,
                editedBy: answeredBy,
                editedQuestion: questionId,
                action: "answer written"
            })
        }

        return true;
      });

      return Object.assign({}, state, { groupNumbers: groupNumbersCopy, answers: answersCopy, editHistory: editHistoryCopy })
    }
    case actions.FETCH_ANSWER_LIST: {
      // //temporary using _id for answerId
      // const answerList = action.payload.map(item => {
      //   return { ...item, answerId: item._id }; 
      // });
      // //EO temporary using _id for answerId

      const answersCopy = [ ...state.answers, ...action.payload ];

      return Object.assign({}, state, { answers: answersCopy })
    }
    case actions.FETCH_NOTES_LIST: {
      // //temporary using _id for answerId
      // const notesList = action.payload.map(item => {
      //   return { ...item, notesId: item._id }; 
      // });
      // //EO temporary using _id for answerId
      
      const notesCopy = [ ...state.notes, ...action.payload ];

      return Object.assign({}, state, { notes: notesCopy })
    }
    case actions.FETCH_TABLE_NOTES_LIST: {
      // //temporary using _id for answerId
      // const notesList = action.payload.map(item => {
      //   return { ...item, notesId: item._id }; 
      // });
      // //EO temporary using _id for answerId
      
      const tableNotesCopy = [ ...state.tableNotes, ...action.payload ];

      return Object.assign({}, state, { tableNotes: tableNotesCopy })
    }
    case actions.DELETE_RESPONSE: {
      return Object.assign({}, state, { groupNumbers: state.groupNumbers.filter(item => 
        item.groupNumberId !== action.payload
      )})
    }
    case actions.ADD_INSURANCE: {
      state.insuranceCo.push(action.payload)

      return state;
    }
    case actions.UPDATE_INSURANCE_LIST: {
      return Object.assign({}, state, { insuranceCo: action.payload })
    }
    case actions.ADD_GROUPNO: {
      const { groupNumberId } = action.payload

      const groupNumberCopy = [...state.groupNumbers ];
      const index = groupNumberCopy.map( x => x.groupNumberId ).indexOf(groupNumberId);

      if (index === -1) {
        groupNumberCopy.push(action.payload);
      }

      return Object.assign({}, state, { groupNumbers: groupNumberCopy });
    }
    case actions.ADD_FORMID: {
      const {insuranceId} = action.payload
      
      state.insuranceCo.filter(item => {
        return item._id === insuranceId
      })
      .map(item => {
        return item.groupNo.push({formId: action.payload.formId})
      })
      
      return state;
    }
    case actions.EDIT_GROUP_NUMBER: {
      const { answer, groupNumberId, answerId, answeredBy, timestamp } = action.payload;

      const insuranceCoCopy = [ ...state.insuranceCo ]
      const groupNumberCopy = [...state.groupNumbers ]
      const answersCopy = [ ...state.answers ]
      const editHistoryCopy = [ ...state.editHistory ]
      
      groupNumberCopy.find(item => {
        if (item.groupNumberId === groupNumberId) {

          item.questionSet
            .filter(itemQuestion => {
                return itemQuestion.questionId === "1g2g3g"
            })
            .map(itemQuestion => {

              const hasDuplicateAnswer = itemQuestion.answers.find(item => {
                return item === answerId
              })

              if (!hasDuplicateAnswer) {
                itemQuestion.answers.push(answerId)

                answersCopy.push({
                  answerId,
                  answer,
                  answeredBy,
                  timestamp
                })

                editHistoryCopy.push({
                  id: answerId,
                  groupNumberId,
                  timestamp,
                  answer,
                  editedBy: answeredBy,
                  editedQuestion: "1g2g3g",
                  action: "answer editted"
                })
              } else {

                answersCopy
                  .filter(singleAnswer => {
                    return singleAnswer.answerId === answerId
                  })
                  .map(singleAnswer => {
                    singleAnswer.answer = answer
                    singleAnswer.timestamp = timestamp

                    return false
                  })

                editHistoryCopy
                  .filter(singleEditHistory => {
                    return singleEditHistory.id === answerId
                  })
                  .map(singleEditHistory => {
                    singleEditHistory.answer = answer
                    singleEditHistory.timestamp = timestamp

                    return false
                  })

              }

              return false
            })
        }
        
        return false
      });

      insuranceCoCopy.map(company => {
        company.groupNo.map(gn => {
          if(gn._id === groupNumberId) {
            gn.groupNumber = answer
          }

          return false
        })

        return false
      })

      return Object.assign({}, state, { 
        insuranceCo: insuranceCoCopy,
        groupNumbers: groupNumberCopy,
        answers: answersCopy,
        editHistory: editHistoryCopy,    
      })
    }
    case actions.SAVE_NOTES: {

      const {
        questionId,
        notes,
        groupNumberId,
        notesId,
        writtenBy,
        timestamp,
        id
      } = action.payload;

      const groupNumbersCopy = [ ...state.groupNumbers ]
      const notesCopy = [ ...state.notes ]
      const editHistoryCopy = [ ...state.editHistory ]

      groupNumbersCopy
        .filter(groupNumber => {
          return groupNumber.groupNumberId === groupNumberId
        })
        .map(groupNumber => {

          // check if the question and note is new
          const questionExist = groupNumber.questionSet
          .some(question => {
            return question.questionId === questionId
          })

          if (!questionExist) {

            groupNumber.questionSet.push({
              questionId,
              answers: [],
              notes: [ id ],
              id
            })

            notesCopy.push(action.payload)

            editHistoryCopy.push({
              id: notesId,
              groupNumberId,
              timestamp,
              notes,
              editedBy: writtenBy,
              editedQuestion: questionId,
              action: "note inserted"
            })

          } else {

            return groupNumber.questionSet
              .filter(question => {
                return question.questionId === questionId
              })
              .map(question => {
                
                const hasDuplicateID = question.notes.find(id => {
                  return id === notesId
                })

                if (!hasDuplicateID) {

                  question.notes.push(notesId)

                  notesCopy.push(action.payload)

                  editHistoryCopy.push({
                    id: notesId,
                    groupNumberId,
                    timestamp,
                    notes,
                    editedBy: writtenBy,
                    editedQuestion: questionId,
                    action: "note inserted"
                  })

                } else {

                  notesCopy 
                    .filter(note => {
                      return note.notesId === notesId
                    })
                    .map(note => {
                      // note.notes = notes
                      // note.timestamp = timestamp
                      // note.writtenBy = writtenBy
                      note = action.payload;

                      return false
                    })

                  editHistoryCopy 
                    .filter(history => {
                      return history.id === notesId
                    })
                    .map(history => {
                      history.timestamp = timestamp
                      history.editedBy = writtenBy
                      history.notes = notes

                      return false
                    })

                }

                return false
              })

          }

          return false
        })

      return Object.assign({}, state, { groupNumbers: groupNumbersCopy, notes: notesCopy, editHistory: editHistoryCopy });
    }
    case actions.SAVE_TABLE_NOTE: {

      const {
        questionId,
        tableNote,
        groupNumberId,
        tableNoteId,
        writtenBy,
        timestamp,
        id
      } = action.payload;

      const groupNumbersCopy = [ ...state.groupNumbers ]
      const tableNotesCopy = [ ...state.tableNotes ]
      const editHistoryCopy = [ ...state.editHistory ]

      groupNumbersCopy
        .filter(groupNumber => {
          return groupNumber.groupNumberId === groupNumberId
        })
        .map(groupNumber => {

          // check if the question and note is new
          const questionExist = groupNumber.questionSet
            .some(question => {
              return question.questionId === questionId
            });

          if (!questionExist) {

            groupNumber.questionSet.push({
              questionId,
              answers: [],
              notes: [ ],
              tableNotes:  [ id ],
              id
            });

            tableNotesCopy.push(action.payload);

            editHistoryCopy.push({
              id: tableNoteId,
              groupNumberId,
              timestamp,
              tableNote,
              editedBy: writtenBy,
              editedQuestion: questionId,
              action: "table note inserted"
            });

          } else {

            return groupNumber.questionSet
              .filter(question => {
                return question.questionId === questionId
              })
              .map(question => {
                
                const hasDuplicateID = question.tableNotes.find(singleId => {
                  return singleId === id
                });

                if (!hasDuplicateID) {

                  question.tableNotes.push(id);

                  tableNotesCopy.push(action.payload);

                  editHistoryCopy.push({
                    id: tableNoteId,
                    groupNumberId,
                    timestamp,
                    tableNote,
                    editedBy: writtenBy,
                    editedQuestion: questionId,
                    action: "table note inserted"
                  });

                } else {

                  tableNotesCopy 
                    .filter(singleTableNote => {
                      return singleTableNote.tableNoteId === tableNoteId
                    })
                    .map(singleTableNote => {
                      singleTableNote = action.payload;

                      return singleTableNote
                    });

                  editHistoryCopy 
                    .filter(history => {
                      return history.id === tableNoteId
                    })
                    .map(history => {
                      history.timestamp = timestamp
                      history.editedBy = writtenBy
                      history.tableNote = tableNote

                      return false
                    });

                }

                return false
              })

          }

          return false
        });

      return Object.assign({}, state, { groupNumbers: groupNumbersCopy, tableNotes: tableNotesCopy, editHistory: editHistoryCopy });
    }
    default:
      return state
  }
}
