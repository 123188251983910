import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import "./App.css";
import { ConnectedRouter } from "connected-react-router";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Loading from "./components/Loading";
import Layout from "./components/Layouts";

const routes = [
  {
    path: "/auth/login/",
    Component: lazy(() => import("./pages/Auth/login/index")),
    exact: true,
  },
  {
    path: "/auth/signup",
    Component: lazy(() => import("./pages/Auth/signup/index")),
    exact: true,
  },
  {
    path: "/auth/forgot-password",
    Component: lazy(() => import("./pages/Auth/forgot-password/ForgotPassword")),
    exact: true,
  },
  {
    path: "/auth/reset-password",
    Component: lazy(() => import("./pages/Auth/reset-password/ResetPassword")),
    exact: true,
  },
  {
    path: "/forms",
    Component: lazy(() => import("./pages/Forms/IndexForms/index")),
    exact: true,
  },
  {
    path: "/create-form",
    Component: lazy(() => import("./pages/Forms/CreateForm/index")),
    exact: true,
  },
  {
    path: "/editformId=:formId",
    Component: lazy(() => import("./pages/Forms/EditForm/index")),
    exact: true,
  },
  {
    path: "/answer-form/formID=:formID",
    Component: lazy(() => import("./pages/Forms/AnswerForm/index")),
    exact: true,
  },
  {
    path: "/print-preview/formID=:formID",
    Component: lazy(() => import("./pages/Forms/AnswerForm/index")),
    exact: true,
  }, 
  {
    path: "/insurance",
    Component: lazy(() => import("./pages/Insurances/InsurancePolicies/index")),
    exact: true,
  },
  {
    path: "/clinics/clinicID=:clinicID",
    Component: lazy(() => import("./pages/Clinics/ClinicsDetails/index")),
    exact: true,
  },
  // Clinics Page to Specific Patient Page
  {
    path: "/clinics/clinicID=:clinicID/patientID=:patientID",
    Component: lazy(() => import("./pages/Clinics/Patient/index")),
    exact: true,
  },
  //Insurance page to insurance data page
  {
    path: "/insurance/insuranceID=:insuranceID",
    Component: lazy(() => import("./pages/Insurances/InsuranceCo/index")),
    exact: true,
  },
  //Insurance page to specific form per group no, both located in Insurance Page and Insurance Data
  {
    path: "/insurance/insuranceID=:insuranceID/groupNumberID=:groupNumberID",
    Component: lazy(() => import("./pages/Insurances/ViewGroupNumberDetails/index")),
    exact: true,
  },
  {
    path: "/insurance/insuranceID=:insuranceID/formID=:formID/sessionID=:sessionID",
    Component: lazy(() => import("./pages/Insurances/AddGroupNumber/AddGroupNumberForm")),
    exact: true,
  },
  {
    path: "/insurance/insuranceID=:insuranceID/formID=:formID/duplicate-assessment",
    Component: lazy(() => import("./pages/Insurances/GroupNumberDuplicateAssessment/DuplicateAssessment")),
    exact: true,
  },
  {
    path: "/insurance/group-number/duplicate/sessionID=:sessionId",
    Component: lazy(() => import("./pages/Insurances/DuplicateGroupNumber/DuplicateGroupNumber")),
    exact: true,
  },
  // Add new patient
  {
    path: "/clinics/clinicID=:clinicID/formID=:formID",
    Component: lazy(() => import("./pages/Clinics/AddPatient/AddPatientForm")),
    exact: true,
  },
  {
    path: "/clinics/clinicID=:clinicID/editpatientID=:patientID",
    Component: lazy(() => import("./pages/Clinics/EditPatient/EditPatientForm")),
    exact: true,
  },
  {
    path: "/patient/responses",
    Component: lazy(() => import("./pages/Clinics/Patient/index")),
    exact: true,
  },
  {
    path: "/clinics",
    Component: lazy(() => import("./pages/Clinics/ClinicsList/index")),
    exact: true,
  },
  {
    path: "/Auth/404",
    Component: lazy(() => import("./pages/Auth/404/index")),
    exact: true,
  },
  {
    path: "/patient-not-found",
    Component: lazy(() => import("./pages/Auth/noPatientFound/index")),
    exact: true,
  },
  {
    path: "/clinics/clinicID=:clinicID/filterformId=:formId",
    Component: lazy(() => import("./pages/Clinics/FilterForm")),
    exact: true,
  },
  {
    path: "/accounts",
    Component: lazy(() => import("./pages/Accounts/UserList/index")),
    exact: true,
  },
  {
    path: "/accounts/userId=:userId",
    Component: lazy(() => import("./pages/Accounts/User/index")),
    exact: true,
  },
  {
    path: "/profile",
    Component: lazy(() => import("./pages/Accounts/Profile/Profile")),
    exact: true,
  },
  {
    path: "/accounts/add-user",
    Component: lazy(() => import("./pages/Accounts/AddUser/index")),
    exact: true,
  },
  {
    path: "/Auth/403",
    Component: lazy(() => import("./pages/Auth/403/index")),
    exact: true,
  },
  {
    path: "/developer/settings",
    Component: lazy(() => import("./pages/DeveloperSettings/index")),
    exact: true,
  },
  {
    path: "/tools",
    Component: lazy(() => import("./pages/Tools/index")),
    exact: true,
  },
  {
    path: "/tools/pdf-to-excel-converter",
    Component: lazy(() => import("./pages/Tools/PdfToExcelConverter/index")),
    exact: true,
  },
];

const App = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  // classNames={routerAnimation}
                  timeout={300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/forms" />}
                    />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div>
                              <Suspense fallback={<Loading />}>
                                <Component />
                              </Suspense>
                            </div>
                          );
                        }}
                      />
                    ))}
                    
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default App;
